<template>
  <el-dialog
    v-drag
    title="新增"
    :visible.sync="dialogVisible"
    width="800px"
    :show-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <div class="form-item">
          <label class="lable"><span class="red">* </span>中文名</label>
          <el-form-item prop="userRoleNameCH">
            <el-input
              v-model="form.userRoleNameCH"
              placeholder="请输入中文名"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red"> </span>英文名</label>
          <el-form-item prop="userRoleNameEN">
            <el-input
              v-model="form.userRoleNameEN"
              placeholder="请输入英文名"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>状态</label>
          <el-form-item prop="isActived">
            <el-switch
              v-model="form.isActived"
              active-color="#2395F3"
              inactive-color="#f1f1f1"
            >
            </el-switch>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { addUserRole } from '@/api/setting'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
    data() {
    return {
      form: {
        userRoleNameCH: '',
        userRoleNameEN: '',
        isActived: true
      },
      rules: {
        userRoleNameCH: [{ required: true, message: '请输入中文名', trigger: 'blur' }]
      }
    }
  },
  methods: {
    cancel() {
      this.$refs['form']?.resetFields()
        this.$emit('cancel')
    },
     ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            addUserRole(this.form).then((res) => {
              if (res.success) {
                this.$message.success('新增成功')
                this.$refs[formName]?.resetFields()
                this.$emit('ok')
              } else {
                this.$message.error(res.errorMessage)
              }
            })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: calc(100% - 120px);
  height: auto;
  margin: 0 auto;
  text-align: left;
  .form {
    display: flex;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
  }
  .form-item {
    width: calc(50% - 20px);
    position: relative;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    &:nth-child(2n) {
      margin-left: 40px;
    }
  }
}
/deep/ .el-dialog__body {
  padding: 20px 20px;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
