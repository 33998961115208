<template>
  <el-dialog
    v-drag
    title="权限分配"
    :visible.sync="dialogFormVisible"
    width="840px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="5%"
  >
    <div class="content">
      <div class="right">
        <div class="title">未分配菜单</div>
        <el-tree
          ref="treeRight"
          style="padding-top: 27px;"
          node-key="keyId"
          :data="unassignedLists"
          show-checkbox
          :props="defaultProps"
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
      <div class="arrow_content">
        <div title="向右移动并保存菜单" class="arrow flex_c_c" @click="right">
          <i class="el-icon-arrow-right"></i>
        </div>
        <div title="向左移动并保存菜单" class="arrow flex_c_c" @click="left">
          <i class="el-icon-arrow-left"></i>
        </div>
      </div>
      <div class="left">
        <div class="title">已分配菜单</div>
        <el-tree
          ref="treeLeft"
          style="padding-top: 27px;"
          node-key="keyId"
          :data="assignedLists"
          show-checkbox
          :props="defaultProps"
          @node-click="handleNodeClick"
        ></el-tree>
      </div>
    </div>
    <span slot="footer" class="footer flex_c_c">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
    </span>
  </el-dialog>
</template>
<script>
import {
  getUserRoleAssignedPermission,
  getUserRoleUnassignedPermission,
  grantPermission
} from '@/api/setting'
import { treeToList } from '@/utils/common'
export default {
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false
    },
    userRoleId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      unassignedLists: null,
      assignedLists: null,
      defaultProps: {
        children: 'children',
        label: 'nameCH'
      }
    }
  },
  watch: {
    dialogFormVisible(val) {
      if (val) {
        this.getList()
      }
    }
  },
  methods: {
    ok() {
      this.$emit('ok')
    },
    cancel() {
      this.$emit('cancel')
    },
    right() {
      const selectArr = this.$refs.treeRight.getCheckedKeys()
      const lists = treeToList(this.unassignedLists, 'children')
      const items = lists.filter((a) => {
        return (
          (a.keyId.indexOf('Button') > -1 || a.keyId.indexOf('Field') > -1) &&
          selectArr.indexOf(a.keyId) > -1
        )
      })
      console.log('items', items)
      const params = {
        userRoleId: this.userRoleId,
        operation: 1,
        permissionItems: []
      }
      items.forEach((item) => {
        params.permissionItems.push(item.keyId)
      })
      grantPermission(params).then((res) => {
        if (res.success) {
          this.$message.success('设置成功, 3S后自动刷新')
          this.getList()
          this.$nextTick(() => {
            setTimeout(() => {
              window.location.reload()
            }, 3000)
          })
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    getList() {
      getUserRoleAssignedPermission({
        userRoleId: this.userRoleId
      }).then((res) => {
        if (res.success) {
          this.assignedLists = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      })
      getUserRoleUnassignedPermission({
        userRoleId: this.userRoleId
      }).then((res) => {
        if (res.success) {
          this.unassignedLists = res.data
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    left() {
      const selectArr = this.$refs.treeLeft.getCheckedKeys()
      const lists = treeToList(this.assignedLists, 'children')
      const items = lists.filter((a) => {
        return (
          (a.keyId.indexOf('Button') > -1 || a.keyId.indexOf('Field') > -1) &&
          selectArr.indexOf(a.keyId) > -1
        )
      })
      console.log('items', items)
      const params = {
        userRoleId: this.userRoleId,
        operation: -1,
        permissionItems: []
      }
      items.forEach((item) => {
        params.permissionItems.push(item.keyId)
      })
      grantPermission(params).then((res) => {
        if (res.success) {
          this.$message.success('设置成功, 3S后自动刷新')
          this.getList()
          this.$nextTick(() => {
            setTimeout(() => {
              window.location.reload()
            }, 3000)
          })
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    handleNodeClick() {}
  }
}
</script>
<style scoped lang="less">
.content {
  width: calc(100% - 40px);
  height: 680px;
  display: flex;
  max-height: 550px;
  margin: 0 auto;
}
.right {
  width: 45%;
  height: 100%;
  overflow: auto;
  border: 1px solid #EEEEEE;
  border-radius: 5px;
}
.arrow_content {
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.arrow_content div {
  margin: 10px 0;
}
.left {
  width: 45%;
  height: 100%;
  overflow: auto;
  border: 1px solid #EEEEEE;
  border-radius: 5px;
}
.title {
  text-align: center;
  color: #303133;
  line-height: 53px;
  height: 53px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  border-bottom:  1px solid #EEEEEE;
}
.arrow {
  width: 40px;
  height: 40px;
  font-size: 20px;
  border: 1px solid #EEEEEE;
  border-radius: 4px 4px 4px 4px;
  color: #666666;
  box-sizing: border-box;
}
.arrow:hover {
  color: #409eff;
  border: 1px solid #409eff;
  cursor: pointer;
}
.cancel {
  width: 120px;
  height: 40px;
  background: #EEEEEE;
  border-radius: 4px 4px 4px 4px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
}
</style>
