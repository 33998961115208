var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "权限分配",
        visible: _vm.dialogFormVisible,
        width: "840px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
        top: "5%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "right" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("未分配菜单")]),
            _c("el-tree", {
              ref: "treeRight",
              staticStyle: { "padding-top": "27px" },
              attrs: {
                "node-key": "keyId",
                data: _vm.unassignedLists,
                "show-checkbox": "",
                props: _vm.defaultProps,
              },
              on: { "node-click": _vm.handleNodeClick },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "arrow_content" }, [
          _c(
            "div",
            {
              staticClass: "arrow flex_c_c",
              attrs: { title: "向右移动并保存菜单" },
              on: { click: _vm.right },
            },
            [_c("i", { staticClass: "el-icon-arrow-right" })]
          ),
          _c(
            "div",
            {
              staticClass: "arrow flex_c_c",
              attrs: { title: "向左移动并保存菜单" },
              on: { click: _vm.left },
            },
            [_c("i", { staticClass: "el-icon-arrow-left" })]
          ),
        ]),
        _c(
          "div",
          { staticClass: "left" },
          [
            _c("div", { staticClass: "title" }, [_vm._v("已分配菜单")]),
            _c("el-tree", {
              ref: "treeLeft",
              staticStyle: { "padding-top": "27px" },
              attrs: {
                "node-key": "keyId",
                data: _vm.assignedLists,
                "show-checkbox": "",
                props: _vm.defaultProps,
              },
              on: { "node-click": _vm.handleNodeClick },
            }),
          ],
          1
        ),
      ]),
      _c(
        "span",
        {
          staticClass: "footer flex_c_c",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }